<template>
    <div>
        <base-header class="pb-1">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--6">
            <card>
                <h3 slot="header" class="mb-0">Relatório de Inadimplência - Cartão de Crédito</h3>
                <div class="row col-md-12">
                    <div class="col-md-3">
                        <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio Tentativa">
                            <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                                :config="{ allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y' }"
                                class="form-control datepicker" placeholder="Data Inicial"
                                v-model="reportFilter.dateBegin">
                            </flat-picker>
                        </base-input>
                    </div>
                    <div class="col-md-3">
                        <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final Tentativa">
                            <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                                :config="{ allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y' }"
                                class="form-control datepicker" placeholder="Data Final" v-model="reportFilter.dateEnd">
                            </flat-picker>
                        </base-input>
                    </div>
                    <div class="col-md-3">
                        <base-input label="Plano" required name="plano">
                            <el-select v-model="reportFilter.plan" filterable placeholder="Plano">
                                <el-option v-for="option in plans" :key="option.id" :label="option.name"
                                    :value="option.id">
                                    <span style="float: left">
                                        {{ option.name }}
                                        <span style="color: #8492a6; font-size: 10px">
                                            Adesão {{ option.accessionFeeFmt }} / Titular {{ option.priceHolderFmt }} /
                                            Dependente {{ option.priceDependentFmt }}
                                        </span>
                                    </span>
                                </el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <div class="col-md-3">
                        <label class="form-control-label">Faturas mais antigas</label>
                        <div class="custom-switch ml-2">
                            <input type="checkbox" v-model="reportFilter.lastInvoice" @change="loadReport"
                                class="custom-control-input" id="antigas" />
                            <label class="custom-control-label form-control-label" for="antigas">Ativar</label>
                        </div>
                    </div>
                </div>
                <div class="row col-md-12 mb-4">
                    <div class="col-md-3">
                        <label class="form-control-label">Dias de Atraso</label>
                        <input type="text" class="form-control" v-model="reportFilter.days" placeholder="Dias de atraso"
                            @keypress.enter="loadReport" maxlength="3" />
                    </div>
                    <div class="col-md-5">
                        <label class="form-control-label">Código de Retorno</label>
                        <select class="form-control" v-model="reportFilter.codes" multiple :select-size="4"
                            style="width: 100%">
                            <option v-for="item in optionsCodes" :key="item.value" :value="item.value">{{ item.text }}
                            </option>
                        </select>
                        <div style="font-size: 12px;">Códigos selecionados: {{ reportFilter.codes }}</div>
                    </div>
                    <div class="col-md-4 text-center">
                        <base-button class="mt-2" type="secundary" native-type="button" @click="exportReport"><i
                                class="far fa-file-excel mr-1"></i>Exportar</base-button>
                        <br>
                        <base-button class="mt-2" type="primary" native-type="button" @click="loadReport"><i
                                class="fas fa-funnel-dollar mr-1"></i> Filtrar</base-button>
                        <br>
                        <base-button
                            v-show="(getProfile() == 'MANAGER' || getProfile() == 'ADMIN') && checkedInvoices.length > 0"
                            class="mt-2" style="margin-right: .5rem" type="primary" native-type="button" @click="retry"
                            :disabled="beginSaveProcess"><i
                                class="fas fa-hand-holding-usd mr-1"></i>Retentar</base-button>
                    </div>
                </div>

                <div class="col-12 text-center m-2">
                    <jw-pagination :pageSize="20" :items="reportResponse.detailsCreditCard" @changePage="onChangePage"
                        :labels="customLabels" :styles="customStyles">
                    </jw-pagination>
                </div>

                <table class="table table-responsive font-tabela-peq">
                    <thead class="thead">
                        <tr>
                            <th scope="col">
                                <input
                                    v-show="(getProfile() == 'MANAGER' || getProfile() == 'ADMIN') && reportResponse.detailsCreditCard.length > 0"
                                    type="checkbox" id="checkbox" @change="checkAll" v-model="checkedInvoicesAll">
                            </th>
                            <th scope="col">Assinatura</th>
                            <th scope="col">Fatura</th>
                            <th scope="col">Titular</th>
                            <th scope="col">Tel. Celular</th>
                            <th scope="col">Tel. Residencial</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Forma de Pagamento</th>
                            <th scope="col">Cartão Informado</th>
                            <!---<th scope="col">Faturas em Aberto</th> -->
                            <th scope="col">Valor em Aberto</th>
                            <th scope="col">Cartão</th>
                            <th scope="col">Última tentativa</th>
                            <th scope="col">Código retorno</th>
                            <th scope="col">Número de tentativas</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="report in pageOfItems" :key="report.invoiceId">
                            <td>
                                <input v-show="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'" type="checkbox"
                                    id="checkbox" :value="report.invoiceId" v-model="checkedInvoices">
                            </td>
                            <td>{{ report.subscriptionId }}</td>
                            <td>{{ report.invoiceId }}</td>
                            <td>{{ report.holderName }}</td>
                            <td>{{ report.cellphone }}</td>
                            <td>{{ report.homephone }}</td>
                            <td>{{ report.mail }}</td>
                            <td>{{ report.paymentType }}</td>
                            <td>{{ report.noCard }}</td>
                            <!-- <td>{{report.quantityInvoices}} fatura<span v-show="report.quantityInvoices > 1">s</span></td> -->
                            <td>{{ report.amountDebtFmt }}</td>
                            <td>
                                <span v-show="report.brand == 'VISA'"><i class="fab fa-cc-mastercard mr-2"
                                        style="font-size: 20px;"></i></span>
                                <span v-show="report.brand == 'MASTERCARD'"><i class="fab fa-cc-visa mr-2"
                                        style="font-size: 20px;"></i></span>
                                {{ report.finalCard }}
                            </td>
                            <td>{{ report.dateLastTry }}</td>
                            <td>{{ report.lastReturnCode }}</td>
                            <td>{{ report.numTries }}</td>
                            <td>
                                <a href="#" title="Assinatura" @click.prevent="open(report.subscriptionId)"><i
                                        class="far fa-folder-open"></i></a>
                                <a href="#" title="Registrar Contato (CallCenter)"
                                    @click.prevent="newContact(report.holderId)"><i class="fas fa-headset ml-2"></i></a>
                                <a v-show="(getProfile() == 'MANAGER' || getProfile() == 'ADMIN') && report.numTries > 0"
                                    href="#" title="Reiniciar tentativas"
                                    @click.prevent="renewTries(report.holderId)"><i class="fas fa-sync ml-2"></i></a>
                            </td>
                        </tr>
                        <tr v-show="reportResponse.detailsCreditCard.length == 0">
                            <td colspan="4">
                                <center> Nenhuma informação disponível no relatório.</center>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </card>

            <!--
        <div class="row">
            <div class="col-md-12" v-if="reportResponse.total != null">
                <card>
                    <h4>Totalização Geral</h4>
                    <p v-for="totalDetail in reportResponse.total" :key="totalDetail.paymentType" class="text-nowrap font-tabela-peq">
                        <span v-if="totalDetail.paymentType == 'DEBIT_CARD'">
                            <i class="fas fa-credit-card mr-2"></i> 
                            Cartão de Débito : <strong>{{totalDetail.totalFmt}}</strong>
                        </span> 
                        <span v-if="totalDetail.paymentType == 'CREDIT_CARD'">
                            <i class="far fa-credit-card mr-2"></i> 
                            Cartão de Crédito : <strong>{{totalDetail.totalFmt}}</strong>
                        </span>
                        <span v-if="totalDetail.paymentType == 'TICKET'">
                            <i class="fas fa-money-check-alt mr-2"></i> 
                            Boleto : <strong>{{totalDetail.totalFmt}}</strong>
                        </span>
                        <span v-if="totalDetail.paymentType == 'TICKETS'">
                            <i class="fas fa-money-check mr-2"></i> 
                            Boleto/Carnê: <strong>{{totalDetail.totalFmt}}</strong>
                        </span>   
                    </p>
                    <hr>
                    <p class="text-nowrap font-tabela-peq"><span class="mr-2">=</span> <i class="fas fa-dollar-sign mr-2"></i> Total Geral : <strong>{{reportResponse.totalGeneralFmt}}</strong></p>
                </card>    
            </div>  
        </div>
        -->
        </div> <!--- fecha conteudo -->

        <modal :show.sync="showModalContact">
            <h4 class="text-center">Callcenter - Registro de Ligação</h4>
            <div class="col-md-4 col-md-8 font-pequena">
                <span>Titular:</span> <span class="ml-1">{{ holderData.name }}</span>
            </div>
            <div class="col-md-12 col-md-12 font-pequena">
                <span>Tel Residencial:</span> <span class="ml-1">{{ holderData.homePhone }}</span>
                <span class="ml-3">Tel Celular:</span> <span class="ml-1">{{ holderData.cellPhone }}</span>
            </div>
            <div class="col-md-2 col-md-10 font-pequena">
                <span>E-mail:</span> <span class="ml-1">{{ holderData.email }}</span>
            </div>
            <div class="col-md-12 mt-2 text-center">
                <textarea rows="5" v-model="contactForm.description" style="width:100%;" class="form-control"
                    placeholder="Registre as informações do contato com cliente"></textarea><br>
                <h4 v-if="protocolo != null"> Protocolo: {{ protocolo }}</h4><br>
                <base-button type="primary" :disabled="beginSaveProcess" @click="saveContact">Registrar
                    Contato</base-button>
            </div>
            <div class="col-md-12 text-center mt-4 font-pequena" v-if="contactHolderList.length == 0"><strong>Não
                    existem registros de ligações para esse titular.</strong></div>
            <div class="row font-pequena scroll-ligacoes mt-2" v-if="contactHolderList.length > 0">
                <div class="col-md-12 font-pequena" v-for="contact in contactHolderList" :key="contact.id">
                    <hr>
                    <span class="font-pequena">Protocolo: <strong>{{ contact.id }}</strong></span><br>
                    <span class="font-pequena">Data/Hora: <strong>{{ contact.dateTimeContact }}</strong></span><br>
                    <span class="font-pequena">Operador: <strong>{{ contact.user.name }}</strong></span><br>
                    <span class="font-pequena">Registro: <strong>{{ contact.description }}</strong> </span>
                </div>
            </div>

        </modal>
    </div>
</template>
<script>
import FileSaver from 'file-saver'
import { Select, Option } from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

const customLabels = {
    first: "<<",
    last: ">>",
    previous: "<",
    next: ">",
};
const customStyles = {
    ul: {
        margin: 0,
        padding: 0,
        display: "inline-block",
    },
    li: {
        listStyle: "none",
        display: "inline",
        textAlign: "center",
    },
    a: {
        cursor: "pointer",
        padding: "7px 7px",
        display: "block",
        float: "left",
    },
};

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker
    },
    mounted() {
        this.loadReport(),
            this.loadPlans()
    },
    data() {
        return {
            pageOfItems: [],
            customLabels,
            customStyles,
            checkedInvoicesAll: false,
            checkedInvoices: [],
            reportFilter: {
                days: 30,
                lastInvoice: false,
                dateBegin: null,
                dateEnd: null,
                plan: null,
                codes: []
            },
            plans: [],
            codes: [],
            optionsCodes: [
                { value: '1', text: '1 - Ano: tamanho do parâmetro inválido' },
                { value: '2', text: '2 - Ano: formato de parâmetro inválido' },
                { value: '3', text: '3 - Ano: Parâmetro obrigatório ausente' },
                { value: '4', text: '4 - Cavv: tamanho do parâmetro inválido' },
                { value: '5', text: '5 - Cavv: formato de parâmetro inválido' },
                { value: '6', text: '6 - PostalCode: tamanho do parâmetro inválido' },
                { value: '7', text: '7 - PostalCode: formato de parâmetro inválido' },
                { value: '8', text: '8 - PostalCode: parâmetro obrigatório ausente' },
                { value: '9', text: '9 - Complemento: tamanho do parâmetro inválido' },
                { value: '10', text: '10 - Formato de parâmetro inválido' },
                { value: '11', text: '11 - Taxa: formato de parâmetro inválido' },
                { value: '12', text: '12 - Número: tamanho do parâmetro inválido' },
                { value: '13', text: '13 - Número: formato de parâmetro inválido' },
                { value: '14', text: '14 - Número: parâmetro obrigatório ausente' },
                { value: '15', text: '15 - Código de segurança: tamanho de parâmetro inválido' },
                { value: '16', text: '16 - Código de segurança: formato de parâmetro inválido' },
                { value: '17', text: '17 - DistributorAffiliation: tamanho de parâmetro inválido' },
                { value: '18', text: '18 - DistributorAffiliation: formato de parâmetro inválido' },
                { value: '19', text: '19 - Xid: tamanho do parâmetro inválido' },
                { value: '20', text: '20 - Eci: formato de parâmetro inválido' },
                { value: '21', text: '21 - Xid: Falta o parâmetro obrigatório para o cartão Visa' },
                { value: '22', text: '22 - Parâmetro obrigatório ausente' },
                { value: '23', text: '23 - Formato de parâmetro inválido' },
                { value: '24', text: '24 - Afiliação: tamanho do parâmetro inválido' },
                { value: '25', text: '25 - Afiliação: formato de parâmetro inválido' },
                { value: '26', text: '26 - Afiliação: parâmetro obrigatório ausente' },
                { value: '27', text: '27 - Parâmetro cavv ou eci ausente' },
                { value: '28', text: '28 - Tamanho do parâmetro inválido' },
                { value: '29', text: '29 - Formato de parâmetro inválido' },
                { value: '30', text: '30 - Parâmetro obrigatório ausente' },
                { value: '31', text: '31 - Descrição: tamanho de parâmetro inválido' },
                { value: '32', text: '32 - Descrição: formato de parâmetro inválido' },
                { value: '33', text: '33 - Mês: formato de parâmetro inválido' },
                { value: '34', text: '34 - Formato de parâmetro inválido' },
                { value: '35', text: '35 - Mês: parâmetro obrigatório ausente' },
                { value: '36', text: '36 - Número: tamanho do parâmetro inválido' },
                { value: '37', text: '37 - Número: formato de parâmetro inválido' },
                { value: '38', text: '38 - Número: parâmetro obrigatório ausente' },
                { value: '39', text: '39 - Tamanho de parâmetro inválido' },
                { value: '40', text: '40 - Referência: formato de parâmetro inválido' },
                { value: '41', text: '41 - Referência: parâmetro obrigatório ausente' },
                { value: '42', text: '42 - Referência: o número do pedido já existe' },
                { value: '43', text: '43 - Número: tamanho do parâmetro inválido' },
                { value: '44', text: '44 - Número: formato de parâmetro inválido' },
                { value: '45', text: '45 - Número: parâmetro obrigatório ausente' },
                { value: '46', text: '46 - Parcelas: Não corresponde à transação de autorização' },
                { value: '47', text: '47 - Origem: formato de parâmetro inválido' },
                { value: '49', text: '49 - O valor da transação excede o autorizado' },
                { value: '50', text: '50 - Parcelas: formato de parâmetro inválido' },
                { value: '51', text: '51 - Produto ou serviço desativado para este comerciante. Fale com a Rede' },
                { value: '53', text: '53 - Operação não permitida para o emissor. Entre em contato com a Rede.' },
                { value: '54', text: '54 - Parcelas: Parâmetro não permitido para esta transação' },
                { value: '55', text: '55 - Nome: tamanho do parâmetro inválido' },
                { value: '56', text: '56 - Erro nos dados relatados. Tente novamente.' },
                { value: '57', text: '57 - Afiliação: comerciante inválido.' },
                { value: '58', text: '58 - Não autorizado. Entre em contato com o emissor.' },
                { value: '59', text: '59 - Nome: formato de parâmetro inválido' },
                { value: '60', text: '60 - Rua: Tamanho do parâmetro inválido' },
                { value: '61', text: '61 - Assinatura: formato de parâmetro inválido' },
                { value: '63', text: '63 - Descrição: Não habilitado para este comerciante' },
                { value: '64', text: '64 - Transação não processada. Tente novamente' },
                { value: '65', text: '65 - Token: token inválido' },
                { value: '66', text: '66 - Taxa: tamanho de parâmetro inválido' },
                { value: '67', text: '67 - Taxa: formato de parâmetro inválido' },
                { value: '68', text: '68 - Taxa: parâmetro obrigatório ausente' },
                { value: '69', text: '69 - Transação não permitida para este produto ou serviço.' },
                { value: '70', text: '70 - Quantidade: tamanho do parâmetro inválido' },
                { value: '71', text: '71 - Quantidade: formato de parâmetro inválido' },
                { value: '72', text: '72 - Entre em contato com o emissor.' },
                { value: '73', text: '73 - Quantidade: parâmetro obrigatório ausente' },
                { value: '74', text: '74 - Falha de comunicação. Tente novamente' },
                { value: '75', text: '75 - Taxa: o parâmetro não deve ser enviado para este tipo de transação' },
                { value: '76', text: '76 - Tipo: formato de parâmetro inválido' },
                { value: '78', text: '78 - Transação não existe' },
                { value: '79', text: '79 - Cartão expirado. A transação não pode ser reenviada. Entre em contato com o emissor.' },
                { value: '80', text: '80 - Não autorizado. Entre em contato com o emissor. (Fundos insuficientes)' },
                { value: '82', text: '82 - Transação não autorizada para cartão de débito.' },
                { value: '83', text: '83 - Não autorizado. Entre em contato com o emissor.' },
                { value: '84', text: '84 - Não autorizado. A transação não pode ser reenviada. Entre em contato com o emissor.' },
                { value: '85', text: '85 - Complemento: tamanho do parâmetro inválido' },
                { value: '86', text: '86 - Cartão expirado' },
                { value: '87', text: '87 - Deve ser preenchido pelo menos um dos seguintes campos: tid ou referência' },
                { value: '88', text: '88 - Comerciante não aprovado. Regularize seu site e entre em contato com a Rede para voltar a negociar.' },
                { value: '89', text: '89 - Token: token inválido' },
                { value: '97', text: '97 - Tid: tamanho de parâmetro inválido' },
                { value: '98', text: '98 - Tid: formato de parâmetro inválido' },
                { value: '101', text: '101 - Não autorizado. Problemas no cartão, entre em contato com o emissor.' },
                { value: '102', text: '102 - Não autorizado. Verifique a situação da loja com o emissor.' },
                { value: '103', text: '103 - Não autorizado. Por favor, tente novamente.' },
                { value: '104', text: '104 - Não autorizado. Por favor, tente novamente.' },
                { value: '105', text: '105 - Não autorizado. Cartao restrito.' },
                { value: '106', text: '106 - Erro no processamento do emissor. Por favor, tente novamente.' },
                { value: '107', text: '107 - Não autorizado. Por favor, tente novamente.' },
                { value: '108', text: '108 - Não autorizado. Valor não permitido para este tipo de cartão.' },
                { value: '109', text: '109 - Não autorizado. Cartão inexistente.' },
                { value: '110', text: '110 - Não autorizado. Tipo de transação não permitido para este cartão.' },
                { value: '111', text: '111 - Não autorizado. Fundos insuficientes.' },
                { value: '112', text: '112 - Não autorizado. A data de expiração expirou.' },
                { value: '113', text: '113 - Não autorizado. Risco moderado identificado pelo emissor.' },
                { value: '114', text: '114 - Não autorizado. O cartão não pertence à rede de pagamento.' },
                { value: '115', text: '115 - Não autorizado. Excedeu o limite de transações permitidas no período.' },
                { value: '116', text: '116 - Não autorizado. Entre em contato com o emissor do cartão.' },
                { value: '117', text: '117 - Transação não encontrada.' },
                { value: '118', text: '118 - Não autorizado. Cartão bloqueado.' },
                { value: '119', text: '119 - Não autorizado. Código de segurança inválido' },
                { value: '120', text: '120 - Transação de zero dólares aprovada com sucesso.' },
                { value: '121', text: '121 - Erro de processamento. Por favor, tente novamente.' },
                { value: '122', text: '122 - Transação enviada anteriormente.' },
                { value: '123', text: '123 - Não autorizado. Portador solicitou o fim das recorrências no emissor.' },
                { value: '124', text: '124 - Não autorizado. Fale com a Rede' },
                { value: '150', text: '150 - Tempo limite. Tente novamente' },
                { value: '151', text: '151 - Parcelas: maior do que o permitido' },
                { value: '153', text: '153 - Número: número inválido' },
                { value: '154', text: '154 - Incorporado: formato de parâmetro inválido' },
                { value: '155', text: '155 - Eci: parâmetro obrigatório ausente' },
                { value: '156', text: '156 - Eci: tamanho de parâmetro inválido' },
                { value: '157', text: '157 - Cavv: parâmetro obrigatório ausente' },
                { value: '158', text: '158 - Captura: tipo não permitido para esta transação' },
                { value: '159', text: '159 - Agente: tamanho de parâmetro inválido' },
                { value: '160', text: '160 - Urls: parâmetro obrigatório ausente (tipo)' },
                { value: '161', text: '161 - Urls: formato de parâmetro inválido' },
                { value: '167', text: '167 - Solicitação JSON inválida' },
                { value: '169', text: '169 - Tipo de conteúdo inválido' },
                { value: '171', text: '171 - Operação não permitida para esta transação' },
                { value: '172', text: '172 - Transação já capturada' },
                { value: '173', text: '173 - Autorização expirada' },
                { value: '176', text: '176 - Urls: parâmetro obrigatório ausente (url)' },
                { value: '200', text: '200 - Titular do cartão autenticado com sucesso' },
                { value: '201', text: '201 - Autenticação não necessária' },
                { value: '202', text: '202 - Portador de cartão não autenticado' },
                { value: '203', text: '203 - Serviço de autenticação não registrado para o estabelecimento comercial. Entre em contato com a Rede' },
                { value: '204', text: '204 - Titular do cartão não registrado no programa de autenticação do emissor' },
                { value: '220', text: '220 - Solicitação de transação com autenticação recebida. URL de redirecionamento enviado' },
                { value: '250', text: '250 - Failure: parâmetro obrigatório ausente' },
                { value: '251', text: '251 - Failure: formato de parâmetro inválido' },
                { value: '252', text: '252 - Urls: parâmetro obrigatório ausente' },
                { value: '253', text: '253 - Urls: tamanho do parâmetro inválido' },
                { value: '254', text: '254 - Urls: formato de parâmetro inválido' },
                { value: '255', text: '255 - Urls: parâmetro obrigatório ausente' },
                { value: '256', text: '256 - Urls: tamanho do parâmetro inválido' },
                { value: '257', text: '257 - Urls: formato de parâmetro inválido' },
                { value: '258', text: '258 - Agente: parâmetro obrigatório ausente' },
                { value: '259', text: '259 - Urls: parâmetro obrigatório ausente' },
                { value: '260', text: '260 - Urls: parâmetro obrigatório ausente' },
                { value: '261', text: '261 - Urls: parâmetro obrigatório ausente' },
                { value: '351', text: '351 - Proibido' },
                { value: '353', text: '353 - Transação não encontrada' },
                { value: '354', text: '354 - Transação com prazo expirado para reembolso' },
                { value: '355', text: '355 - Transação já cancelada.' },
                { value: '357', text: '357 - Soma de reembolsos de valor maior que o valor da transação' },
                { value: '358', text: '358 - Soma de reembolsos de valor maior do que o valor processado disponível para reembolso' },
                { value: '359', text: '359 - Reembolso bem-sucedido' },
                { value: '360', text: '360 - O pedido de reembolso foi bem sucedido' },
                { value: '362', text: '362 - RefundId não encontrado' },
                { value: '363', text: '363 - caracteres de URL de retorno de chamada excederam 500' },
                { value: '365', text: '365 - Reembolso parcial não disponível.' },
                { value: '368', text: '368 - Sem sucesso. Por favor, tente novamente' },
                { value: '369', text: '369 - Reembolso não encontrado' },
                { value: '370', text: '370 - Solicitação falhou. Fale com a Rede' },
                { value: '371', text: '371 - Transação não disponível para reembolso. Tente novamente em algumas horas' },
                { value: '899', text: '899 - Falha. Por favor contate a Rede.' },
                { value: '3025', text: '3025 - Categoria recusada 01: Este cartão não pode ser utilizado' },
                { value: '3026', text: '3026 - Categoria recusada 02: Este cartão não pode ser utilizado neste PV' },
                { value: '3027', text: '3027 - Categoria recusada 03: Nenhum cartão pode ser utilizado neste PV' },
            ],
            protocolo: null,
            beginSaveProcess: false,
            showModalContact: false,
            contactForm: {
                description: null,
                holder: {
                    id: null
                }
            },
            reportResponse: {
                detailsCreditCard: [],
                total: []
            },
            contactHolderList: [],
            holderData: {
                name: null,
                email: null,
                homePhone: null,
                cellPhone: null
            }
        }
    },
    methods: {
        onChangePage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        getProfile() {
            return localStorage.getItem("profile")
        },
        checkAll() {
            if (this.checkedInvoicesAll) {
                this.reportResponse.detailsCreditCard.forEach(fatura => {
                    this.checkedInvoices.push(fatura.invoiceId);
                });
            } else {
                this.checkedInvoices = [];
            }
        },
        newContact(holderId) {
            this.protocolo = null
            this.contactForm.description = null
            this.$clubApi.get('/holder/' + holderId)
                .then((response) => {
                    this.holderData = response.data.object
                    this.contactForm.holder.id = holderId
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    NProgress.done()
                })

            //obtendo ultimas ligacoes para o titular
            this.$clubApi.get('/contact-holder/holder/' + holderId)
                .then((response) => {
                    this.contactHolderList = response.data.object
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    NProgress.done()
                })
            this.showModalContact = true
        },
        renewTries(holderId) {
            this.$clubApi.post('/report/debt/renewTries/' + holderId)
                .then((response) => {
                    this.loadReport();
                    this.$notify({ type: 'success', message: "Tentativas reiniciadas com sucesso!" })
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    NProgress.done()
                })
        },
        saveContact() {
            this.beginSaveProcess = true
            this.$clubApi.put('/contact-holder', this.contactForm)
                .then((response) => {
                    this.newContact(response.data.object.holder.id)//reload 
                    this.$notify({ type: 'success', message: 'Registro efetuado!' })
                    this.protocolo = response.data.object.id
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    this.beginSaveProcess = false
                    NProgress.done()
                })
        },
        open(subscriptionId) {
            location.href = '/assinatura/' + subscriptionId
        },
        loadReport() {
            this.reportResponse = {
                detailsCreditCard: [],
                total: []
            }
            this.$clubApi.post('/report/debtCard', this.reportFilter).then((response) => {
                this.reportResponse = response.data.object
            }).catch((error) => {
                this.$notify({ type: 'warning', message: error.response.data.msg })
            }).finally(() => {
                NProgress.done()
            })
        },
        exportReport() {
            this.$clubApi.post('/report/debtCard/export', this.reportFilter, {
                responseType: 'blob'
            }).then((response) => {
                var blob = new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                });
                FileSaver.saveAs(blob, 'relatorio-inadimplentes.xlsx');
            }).catch((error) => {
                this.$notify({ type: 'warning', message: error.response.data.msg })
            }).finally(() => {
                NProgress.done()
            })
        },
        retry() {
            this.beginSaveProcess = true;
            if (this.checkedInvoices.length > 0) {
                this.$clubApi.post('/report/debt/retry', this.checkedInvoices).then((response) => {
                    this.$notify({ type: 'success', message: 'Tentativas de pagamento realizadas!' });
                    this.loadReport();
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    NProgress.done()
                    this.beginSaveProcess = false;
                })
            }
        },
        loadPlans() {
            this.$clubApi.get('/plan/list/active/')
                .then((response) => {
                    this.plans = response.data.object
                }).catch(() => {
                    this.$notify({ type: 'warning', message: 'Erro ao carregar listagem de planos disponiveis.' })
                }).finally(() => {
                    NProgress.done()
                })
        },
    }
};
</script>

<style>
.font-pequena {
    font-size: 10px;
}

.scroll-ligacoes {
    overflow-y: scroll;
    overflow-x: none;
    height: 300px;
}

.vue-tablist {
    list-style: none;
    display: flex;
    padding-left: 0;
    border-bottom: 1px solid #e2e2e2;
}

.vue-tab {
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    border-bottom-color: #e2e2e2;
    border-radius: 3px 3px 0 0;
    background-color: white;
    position: relative;
    bottom: -1px;
    font-size: 13px;
}

.vue-tab[aria-selected="true"] {
    border-color: #e2e2e2;
    border-bottom-color: transparent;
    background-color: #f8f8ff;
}

.vue-tab[aria-disabled="true"] {
    cursor: not-allowed;
    color: #999;
}
</style>